import { CommandBase, IParams } from '../framework/CommandBase';
import { Position } from '../../types';
import { EventBase } from '../framework/EventBase';
import { AttachmentType, EntityType } from '../../entities/EntityType';
import { EntityBase } from '../../entities/EntityBase';
import { EntityParserFactory } from '../../entities/EntityParserFactory';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { CommandError } from '../../ErrorStore';
import { Attachment } from '../../entities/assets/Attachment';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Query } from '../../entities/assets/Query';
import { GqlEntityBase } from '../../entities/gql-entities/GqlEntityBase';
import { GqlOperation } from '../../entities/gql-entities/GqlOperation';
import { sid } from '@xspecs/short-id';
import { Edge, HandleLocation } from '../../entities/transitions/Edge';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

export interface BaseParams extends IParams {
  id: string;
  name: string;
  type: string;
  position: Position;
  attributes?: Record<string, any>;
}

type AddGqlEntityParams = BaseParams & {
  type: EntityType.GqlField | EntityType.GqlOperation;
  queryId: string;
};
export type AddEntityParams =
  | (BaseParams & {
      type: EntityType.Attachment;
      subType: AttachmentType;
      assetId?: string;
      autoCreate?: boolean;
    })
  | (BaseParams & {
      type: EntityType.Thread;
      createdBy: string;
    })
  | (BaseParams & {
      type: string;
    })
  | (BaseParams & {
      type: Exclude<EntityType, EntityType.Attachment | EntityType.Thread>;
      subType?: never;
      createdBy?: never;
      assetId?: never;
    })
  | AddGqlEntityParams;

interface EntitiesAddedParams extends IParams {
  entityIds: string[];
  cursorPosition: Position;
}

function isEntityType(type: string): type is EntityType {
  return Object.values(EntityType).includes(type as EntityType);
}

export class EntitiesAddedEvent extends EventBase {
  static eventType = 'EntityAddedEvent';

  constructor(
    public readonly params: EntitiesAddedParams,
    public readonly source = AddEntityCommand,
  ) {
    super();
  }
}

export class AddEntityCommand extends CommandBase<AddEntityParams> {
  execute(params: AddEntityParams): EntitiesAddedEvent | CommandError {
    const entities = this.createEntities(params);
    if (entities instanceof CommandError) return entities;
    entities.forEach((entity) => {
      this.getModelContext().entityRepository.add(entity);
      if (!entity?.attributes?.createdBy) {
        entity.attributes.createdBy = {
          id: this.appState.currentUser.sub,
          name: this.appState.currentUser.name,
          origin: this.appState.currentUser.sub?.startsWith('auto|ai|') ? 'ai' : 'user',
        };
      }
      if (!entity?.attributes?.suggested) {
        entity.attributes.suggested = false;
      }
      entity.attributes.createdAt = new Date().toISOString();
    });
    return new EntitiesAddedEvent({
      entityIds: entities.map((entity) => entity.id),
      cursorPosition: params.position,
    });
  }

  private createEntities(params: AddEntityParams): EntityBase[] | CommandError {
    const data = { ...params };
    const entity = EntityParserFactory.parse(data, params.type as any, undefined, true);
    const entityInstance = EntityParserFactory.getEntityInstance(params.type);
    if (entityInstance instanceof ConstructBase && entityInstance.hasRequiredScript) {
      const constructWithScript = entity as ConstructBase;
      const type = (entityInstance as any).scriptType;
      constructWithScript.script = EntityParserFactory.parse({
        name: 'script',
        id: `${constructWithScript.id}_script`,
        position: {
          // FIXME THIS BREAKS TESTS, WE NEED TO FIX THOSE TESTS
          x: params.position.x + constructWithScript.width + 35,
          y: params.position.y - 160,
          // x: params.position.x + constructWithScript.width * 1.5,
          // y: params.position.y + constructWithScript.height * 2,
        },
        type: type,
        scopes: [],
      });
      constructWithScript.script.parent = constructWithScript;
      ScriptBase.initialize(constructWithScript.script, true);
      if (params?.attributes?.suggested) {
        constructWithScript.script.attributes.suggested = params.attributes.suggested;
      }
      const edge = this.createEdgeBetweenConstructAndScript(constructWithScript);
      return [constructWithScript, constructWithScript.script, edge];
    }

    const modelContext = this.getModelContext();
    switch (params.type) {
      case EntityType.Attachment:
        if ('assetId' in params && params.assetId) {
          const asset = modelContext.entityRepository.get<AssetBase>(params.assetId);
          if (!asset) {
            return CommandError.of(new Error(`Asset with id ${params.assetId} not found`), 'error');
          }
          const attachment = entity as Attachment;
          attachment.linkAsset(asset);
          modelContext.entityRepository.update(asset);
          return [attachment];
        }
        if ('autoCreate' in params && params.autoCreate) {
          const attachment = EntityParserFactory.parse(data) as Attachment;
          const asset = EntityParserFactory.parse(
            {
              ...data,
              id: `${data.id}_${params.subType}`,
            },
            EntityType[params.subType],
          );
          modelContext.entityRepository.add(asset);
          attachment.linkAsset(asset as AssetBase);
          modelContext.entityRepository.update(asset);
          return [attachment, asset];
        }
        return [entity];
      case EntityType.GqlField: {
        const returnEntities: EntityBase[] = [entity];
        const query = modelContext.entityRepository.get<Query>((params as AddGqlEntityParams).queryId);
        if (!(query instanceof Query))
          return CommandError.of(
            new Error(`Query with id ${(params as AddGqlEntityParams).queryId} not found`),
            'error',
          );
        if (params.type === EntityType.GqlField && !query.gqlEntities.find((p) => p.type === EntityType.GqlOperation)) {
          const gqlOperation = this.createGqlOperation(query, params);
          query.addGqlEntity(gqlOperation);
          modelContext.entityRepository.add(gqlOperation);
          returnEntities.push(gqlOperation);
        }
        query.addGqlEntity(entity as GqlEntityBase);
        modelContext.entityRepository.add(entity);
        query.syncGqlEntitiesToQuery();
        modelContext.entityRepository.update(query);
        return returnEntities;
      }
      case EntityType.Query: {
        const query = entity as Query;
        const gqlOperation = this.createGqlOperation(query, params);
        query.addGqlEntity(gqlOperation);
        query.syncGqlEntitiesToQuery();
        modelContext.entityRepository.add(gqlOperation);
        return [query, gqlOperation];
      }
      default:
        return [entity];
    }
  }

  private createGqlOperation(query: Query, params: Pick<BaseParams, 'id' | 'name' | 'type' | 'position'>) {
    return EntityParserFactory.parse<GqlOperation>({
      id: sid(),
      name: query.operationName,
      type: EntityType.GqlOperation,
      position: params.position,
      scopes: [],
      parentId: query.id,
    });
  }

  private createEdgeBetweenConstructAndScript(construct: ConstructBase) {
    return new Edge({
      id: sid(),
      source: construct,
      target: construct.script,
      sourceHandleLocation: HandleLocation.Right,
      targetHandleLocation: HandleLocation.Left,
    });
  }
}
