import { NodeProps, Node } from '@xyflow/react';
import { useEffect, useRef } from 'react';
import { ConstructBase, DebugNodeData, EntityType } from '@xspecs/single-source-model';
import { lightTheme } from '../../../../themes/light';

export const DebugNode = (props: NodeProps<Node<DebugNodeData>>) => {
  const { data } = props;
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (nodeRef.current) {
      const parentElement = nodeRef.current.parentElement;
      if (parentElement) parentElement.style.pointerEvents = 'none';
    }
  }, []);

  const color =
    data.type === 'Script' || data.type === 'SubScript'
      ? `rgba(0, 255, 0)`
      : data.type === 'Lane'
        ? 'rgba(255,0,0)'
        : data.type === 'Frame'
          ? 'rgba(255,0,255)'
          : data.entity instanceof ConstructBase
            ? 'rgba(0,0,255)'
            : data.type === 'Attachment'
              ? 'rgba(0,127,127)'
              : data.type === 'Interface'
                ? 'rgba(0, 0, 0, .5)'
                : lightTheme.palette.constructs[data.type as EntityType]?.color;

  if (data.type && ['Edge', 'Comment'].includes(data.type)) {
    return <></>;
  }

  return (
    <div
      ref={nodeRef}
      style={{
        width: `${data.width}px`,
        height: `${data.height}px`,
        border: `1px ${color} dashed`,
        pointerEvents: 'none',
        // backgroundColor: 'green',
      }}
      className="nodrag"
    >
      <span
        style={{
          pointerEvents: 'none',
          color,
          position: 'absolute',
          bottom: -15,
          right: -100,
          transform: 'rotate(30deg)',
          borderRadius: 5,
          padding: '0 5px',
          width: 50,
          height: 20,
        }}
      >{`${data.id.replace('', '')}`}</span>
      {/*<span*/}
      {/*  style={{*/}
      {/*    pointerEvents: 'none',*/}
      {/*    color,*/}
      {/*    transform: `rotate(${['Attachment', 'Lane'].includes(data.type) ? 0 : 90}deg)`,*/}
      {/*    fontSize: 9,*/}
      {/*    fontStyle: 'italic',*/}
      {/*    backgroundColor: 'white',*/}
      {/*    textAlign: data.type === 'Lane' ? 'right' : 'left',*/}
      {/*    position: 'absolute',*/}
      {/*    left:*/}
      {/*      data.type === 'Script'*/}
      {/*        ? -50*/}
      {/*        : data.type === 'SubScript'*/}
      {/*        ? -60*/}
      {/*        : data.type === 'Frame'*/}
      {/*        ? -30*/}
      {/*        : data.entity instanceof ConstructBase || data.entity instanceof Capability*/}
      {/*        ? -60*/}
      {/*        : data.type === 'Attachment'*/}
      {/*        ? 5*/}
      {/*        : undefined,*/}
      {/*    top:*/}
      {/*      data.type === 'Script'*/}
      {/*        ? 35*/}
      {/*        : data.type === 'SubScript'*/}
      {/*        ? 45*/}
      {/*        : data.type === 'Frame'*/}
      {/*        ? 60*/}
      {/*        : data.type === 'Lane'*/}
      {/*        ? 180*/}
      {/*        : data.entity instanceof ConstructBase || data.entity instanceof Capability*/}
      {/*        ? 35*/}
      {/*        : data.type === 'Attachment'*/}
      {/*        ? -15*/}
      {/*        : -100,*/}
      {/*    right: data.type === 'Lane' ? 10 : undefined,*/}
      {/*  }}*/}
      {/*>{`${data.type} ${data.frameId},${data.laneId}`}</span>*/}
    </div>
  );
};
