import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { SchemeRegistry } from '../../apps/SchemeRegistry';

export class SchemeSetEvent extends EventBase {
  static eventType = 'SchemeSetEvent';

  constructor(
    public readonly params: CreateSchemeParams,
    public readonly source = SetSchemeCommand,
  ) {
    super();
  }
}

interface CreateSchemeParams extends IParams {
  fileExtension: string;
  spaceId: string;
}

export class SetSchemeCommand extends CommandBase<CreateSchemeParams> {
  execute(params: CreateSchemeParams): EventBase | CommandError | undefined {
    const scheme = SchemeRegistry.getSchemeByFileExtension(params.fileExtension);
    if (!scheme) {
      return CommandError.of(new Error('Scheme not found'), 'error');
    }
    SchemeRegistry.setCurrentScheme(scheme);
    this.appState.toolbar.clearSchemes();
    this.appState.toolbar.addScheme(scheme);
    return new SchemeSetEvent(params);
  }
}
