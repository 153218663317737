import { IconButton as MuiIconButton, Stack, Tooltip } from '@mui/material';
import { Menu } from '@szhsin/react-menu';
import { ExpandMore } from '@mui/icons-material';
import { ToolbarElementProps, ToolbarIconButtonElement, ToolbarMenuElement } from '@xspecs/single-source-model';
import { DragEventHandler, FC, MouseEvent, useCallback, useMemo, useRef } from 'react';
import { Icon } from '@xspecs/design-system';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';
import { renderPaletteComponent } from './palette-renderer';
import { isUrl } from '../../../lib/utils';

export const IconButton: FC<ToolbarElementProps<ToolbarIconButtonElement>> = (props) => {
  const { element } = props;

  const dispatch = useCommandDispatch();
  const previewRef = useRef<SVGSVGElement>(null);

  const active = element?.more && 'active' in element.more ? element.more.active : false;

  const onClick = useCallback(() => {
    if (element.onClick) dispatch(element.onClick.command, element.onClick.params);
  }, [dispatch, element.onClick]);

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      if (!element.onDragStart) return;
      dispatch(element.onDragStart.command, element.onDragStart.params);
      event.dataTransfer.setData('application/reactflow', element.onDragStart.params.value as string);
      event.dataTransfer.effectAllowed = 'move';
      if (previewRef.current) event.dataTransfer.setDragImage(previewRef.current, 0, 0);
    },
    [dispatch, element.onDragStart],
  );

  const onDragEnd = useCallback<DragEventHandler>(() => {
    if (element.onDragEnd) {
      dispatch(element.onDragEnd.command, element.onDragEnd.params);
    }
  }, [dispatch, element.onDragEnd]);

  const more = useMemo(() => {
    if (!element.more) return null;

    if ('type' in element.more) {
      return <MenuButton element={element.more} />;
    }

    if (element.more.onClick && element.more.onClick.command) {
      const { onClick } = element.more;

      return <MenuIconButton onClick={() => dispatch(onClick.command, onClick.params)} />;
    }

    return null;
  }, [dispatch, element.more]);

  let button = (
    <MuiIconButton
      sx={{ ...buttonSx }}
      onClick={onClick}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      draggable={Boolean(element.onDragStart)}
      disableRipple
      disableTouchRipple
    >
      {isUrl(element.icon) ? (
        <img alt={`${element.type}Icon`} src={element.icon} style={imgStyle} />
      ) : (
        <Icon ref={previewRef} name={element.icon} color={element.iconColor} iconBorder={element.iconBorder} />
      )}
    </MuiIconButton>
  );

  if (element.tooltip) {
    button = (
      <Tooltip title={element.tooltip} arrow placement="top">
        {button}
      </Tooltip>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{ ...element.style, px: 1, borderRadius: '4px', backgroundColor: active ? 'action.selected' : '' }}
    >
      {button}
      {more}
    </Stack>
  );
};

type MenuIconButtonProps = {
  onClick: (event?: MouseEvent<HTMLButtonElement>) => void;
};
const MenuIconButton = (props: MenuIconButtonProps) => {
  const { onClick } = props;

  return (
    <MuiIconButton sx={{ ...buttonWithoutPadding }} size="small" onClick={onClick}>
      <ExpandMore sx={iconSx} className="text-icon-muted" />
    </MuiIconButton>
  );
};

const MenuButton: FC<ToolbarElementProps<ToolbarMenuElement>> = (props) => {
  const { element } = props;

  return (
    <Menu
      menuButton={
        <MuiIconButton sx={buttonWithoutPadding} size="small">
          <ExpandMore sx={iconSx} className="text-icon-muted" />
        </MuiIconButton>
      }
      menuStyle={{ paddingLeft: 12, paddingRight: 12, backgroundColor: 'hsl(var(--background-paper))' }}
      direction="top"
    >
      {element.elements.map((element, index) =>
        renderPaletteComponent({
          element,
          key: `MenuButton${index}`,
        }),
      )}
    </Menu>
  );
};

const iconSx = { fontSize: '16px' };

const buttonSx = {
  borderRadius: 1,
  paddingLeft: 0,
  paddingRight: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const buttonWithoutPadding = {
  ...buttonSx,
  p: 0,
};

const imgStyle = {
  width: 24,
  height: 24,
  minWidth: 24,
  minHeight: 24,
};
