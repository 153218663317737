import { AllowedAction, ConstructShape, FileType, ISchemeProvider, Scheme, SchemeBuilder } from 'narrative-studio-sdk';
import { EntityType } from '../../entities/EntityType';

export class NarrativeSchemeProvider implements ISchemeProvider {
  getScheme(): Scheme {
    const origin = typeof window === 'object' ? window.location.origin : '';
    const scheme = SchemeBuilder.create({ name: 'Narrative', fileExtension: 'ndd', defaultConstruct: 'Narrative' })
      .addCategory('ndd')
      .addConstruct({
        description: "Represents a system's capability",
        label: 'Capability',
        type: 'Capability',
        style: { backgroundColor: '#E735BF', textColor: 'white' },
        shape: ConstructShape.RECTANGLE,
      })
      .addConstruct({
        label: 'Narrative',
        type: 'Narrative',
        icon: `${origin}/narrative.svg`,
        style: { backgroundColor: '#F0E1F9', textColor: '#000000' },
        description: 'Represents a sequence of events and interactions',
        shape: ConstructShape.RECTANGLE,
      })
      .addScript({
        type: 'NarrativeScript',
      })
      .addFrameGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.REMOVE] },
        frameGroupLimits: { min: 1, max: 1 },
        style: { backgroundColor: 'transparent', borderWidth: 1, borderColor: '#E4E4E8' },
        frameLimits: { min: 1, max: Infinity },
        defaultFrameWidth: 200,
        conflictingEntityGroups: [[EntityType.Interface, EntityType.Action]],
      })
      .addFrame({})
      .addFrame({})
      .addFrame({})
      .addFrame({})
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.NONE] },
        laneGroupLimits: { min: 1, max: 1 },
        laneLimits: { min: 3, max: 3 },
        autoIngestInCorrectLane: true,
        defaultLaneHeight: 200,
      })
      .addLane({
        label: { text: 'Interaction', alignment: 'left' },
        allowedEntities: { type: 'SPECIFIC', entities: [EntityType.Interface] },
        entityLimits: { min: 0, max: 1 },
        style: { backgroundColor: '#F8F8F8', textColor: '#52525B' },
        darkModeStyle: { backgroundColor: '#3F3F46', textColor: '#D4D4D8' },
      })
      .addLane({
        label: { text: 'Context' },
        allowedEntities: { type: 'SPECIFIC', entities: ['Moment'] },
        entityLimits: { min: 1, max: 1 },
        style: { backgroundColor: '#F5F0F8', textColor: '#52525B' },
        darkModeStyle: { backgroundColor: '#6B21A8', textColor: '#D4D4D8' },
      })
      .addLane({
        label: { text: 'System' },
        allowedEntities: { type: 'SPECIFIC', entities: [EntityType.Action] },
        entityLimits: { min: 0, max: 1 },
        style: { backgroundColor: '#F0E1F9', textColor: '#52525B' },
        darkModeStyle: { backgroundColor: '#581C87', textColor: '#D4D4D8' },
      })
      .addConstruct({
        label: 'Moment',
        type: 'Moment',
        description: 'Specific moment or interaction within a narrative',
        style: { backgroundColor: '#8B90FF', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
      })
      .addConstruct({
        label: 'Interface',
        type: 'Interface',
        description: 'UI component or interaction element visible to the user',
        style: { backgroundColor: '#FFFFFF', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
      })
      .addConstruct({
        label: 'Action',
        type: 'Action',
        description: 'System-level actions that occur in the background',
        style: { backgroundColor: '#4a4a4a' },
        shape: ConstructShape.RECTANGLE,
      })
      .addScript({
        type: 'ActionScript',
      })
      .addFrameGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.UPDATE, AllowedAction.REMOVE] },
        frameGroupLimits: { min: 1, max: 1 },
        frameLimits: { min: 1, max: Infinity },
        defaultFrameWidth: 200,
      })
      .addFrame({})
      .addFrame({})
      .addFrame({})
      .addFrame({})
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.UPDATE, AllowedAction.REMOVE] },
        laneGroupLimits: { min: 1, max: 1 },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: {
          type: 'SPECIFIC',
          entities: [
            EntityType.Command,
            EntityType.Data,
            EntityType.Event,
            EntityType.Process,
            EntityType.Constraints,
            EntityType.ExternalSystem,
            EntityType.Resolver,
            EntityType.ReadModel,
            EntityType.Projection,
            EntityType.Gateway,
          ],
        },
        autoIngestInCorrectLane: false,
        entityLimits: { min: 1, max: 1 },
        defaultLaneHeight: 200,
        style: { backgroundColor: '#F8F8F8', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#3C3F41', textColor: '#FAFAFA' },
      })
      .addLane({
        style: { backgroundColor: '#F8F8F8' },
        darkModeStyle: { backgroundColor: '#3C3F41' },
      })
      .addCategory('Documentation & Specifications')
      .addAsset({
        type: 'Spec',
        label: 'Spec',
        description: 'Requirements or behavior descriptions',
        icon: `${origin}/spec.svg`,
        fileConfig: {
          type: FileType.SPEC,
        },
      })
      .addAsset({
        type: 'Doc',
        label: 'Doc',
        icon: `${origin}/doc.svg`,
        description: 'Rich text documentation, serving as a reference of guide',
        fileConfig: {
          type: FileType.SLATE,
        },
      })
      .addAsset({
        type: 'Query',
        label: 'Query',
        description: 'Retrieves specific data from a system based on defined criteria',
        icon: `${origin}/query.svg`,
        fileConfig: {
          type: FileType.GQL,
        },
      })
      .addAsset({
        type: 'Schema',
        label: 'Schema',
        icon: `${origin}/schema.svg`,
        description: 'Defines the shape of the data that will be used',
        fileConfig: {
          type: FileType.GQL,
        },
      })
      .addAsset({
        type: 'Actor',
        label: 'Actor',
        icon: `${origin}/actor.svg`,
        description: 'Represents an actor in the system',
        fileConfig: {
          type: FileType.SLATE,
        },
      })
      .addCategory('Information Flow')
      .addConstruct({
        type: 'Command',
        label: 'Command',
        description: 'Input action that starts a business process',
        style: { backgroundColor: '#ACD0F7', textColor: '#000000' },
        shape: ConstructShape.SQUARE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'Data',
        label: 'Data',
        description: 'Defines the type of data handled in the system',
        style: { backgroundColor: '#C1E558', textColor: '#000000' },
        shape: ConstructShape.SQUARE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'Event',
        label: 'Event',
        description: 'Signals a system occurrence, like ‘Order Placed’ or ‘Payment Completed',
        style: { backgroundColor: '#FF9C48', textColor: '#000000' },
        shape: ConstructShape.SQUARE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addCategory('Business Process Model')
      .addConstruct({
        type: 'Process',
        label: 'Process',
        description: 'A sequence of steps to achieve a particular business goal',
        style: { backgroundColor: '#C6A2D2', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'Constraints',
        label: 'Constraints',
        description: 'Business rules that dictate conditions for a process to proceed',
        style: { backgroundColor: '#FFF9B1', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'ExternalSystem',
        label: 'External System',
        description: 'Dependencies outside the core domain',
        style: { backgroundColor: '#FFD0DF', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addCategory('Software Model')
      .addConstruct({
        type: 'Resolver',
        label: 'Resolver',
        description: 'Processes queries by mapping requests to data sources or computations',
        style: { backgroundColor: '#9BC916', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'ReadModel',
        label: 'Read Model',
        description: 'Projections of data, optimized for queries and separated from the main data store',
        style: { backgroundColor: '#67C6C0', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'Projection',
        label: 'Projection',
        description: 'A derived view created from events, used for querying',
        style: { backgroundColor: '#FA8A7B', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .addConstruct({
        type: 'Gateway',
        label: 'Gateway',
        description: 'Mediates between external systems, contexts, or services',
        style: { backgroundColor: '#F685A9', textColor: '#000000' },
        shape: ConstructShape.RECTANGLE,
        fileConfig: { type: FileType.JSON, defaultValue: '{}' },
      })
      .build();
    return scheme;
  }
}
