import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface FollowUserCommandParams extends IParams {
  fileId: string;
  clientId: string | null;
}

export class UserFollowedEvent extends EventBase {
  static eventType = 'UserFollowedEvent';

  constructor(
    public readonly params: FollowUserCommandParams,
    public readonly source = FollowUserCommand,
  ) {
    super();
  }
}

export class FollowUserCommand extends CommandBase<FollowUserCommandParams> {
  shouldSave(): boolean {
    return false;
  }

  execute(params: FollowUserCommandParams): UserFollowedEvent | CommandError {
    const { clientId, fileId } = params;
    // console.log('Follow user command executed', params);

    const file = this.appState.fileStoreClient.files[fileId];
    if (!file) {
      return CommandError.of(new Error(`File ${fileId} not found`), 'error');
    }
    if (file.activeUser.id === clientId) {
      return CommandError.of(new Error(`Cannot follow yourself`), 'error');
    }

    this.getModelContext().followedUser.update({ fileId, clientId });

    return new UserFollowedEvent({
      ...params,
    });
  }
}
