import React from 'react';
import { Box, Stack } from '@mui/material';
import { CanvasToolbarHeader } from './header/canvas-toolbar-header';
import { ToolbarState } from '@xspecs/single-source-model';

type CanvasToolbarProps = {
  toolbar: ToolbarState;
};

export const CanvasToolbar = (props: CanvasToolbarProps) => {
  const { toolbar } = props;

  const isBodyCollapsed = !toolbar.isOpen;

  return (
    <Box
      sx={{
        width: '100%',
        height: 58,
        position: 'absolute',
        bottom: 0,
        left: 0,
        overflow: 'visible',
        pointerEvents: 'none !important',
        '& .split-view': {
          '--focus-border': 'transparent',
          overflow: 'visible !important',
          zIndex: 100000000,
        },
        '& .split-view-view': {
          overflow: 'visible !important',
        },
        '& .split-view-view:first-of-type': {
          pointerEvents: 'none !important',
        },
      }}
    >
      <Stack alignItems="center">
        <CanvasToolbarHeader expanded={!isBodyCollapsed} {...toolbar.layout.header} />
      </Stack>
    </Box>
  );
};
