import { MessageBus } from '../../commands/framework/MessageBus';
import { ToolbarStateChangedEvent } from '../../commands/toolbar/SetToolbarStateCommand';
import { Toolbar } from './Toolbar';
import { logger } from '@xspecs/logger';
import { ResolvedThreadVisibilityToggledEvent } from '../../commands/comments/ToggleResolvedThreadsVisibilityCommand';
import { DevModeToggleEvent } from '../../commands/ide/ToggleDevModeCommand';

export class ToolbarProjection {
  constructor(
    private readonly messageBus: MessageBus,
    private readonly toolbar: Toolbar,
    private readonly owner: string,
  ) {
    messageBus.subscribe([ToolbarStateChangedEvent], this.onToolbarStateChangedEvent.bind(this), this.owner);
    this.messageBus.subscribe(
      [ResolvedThreadVisibilityToggledEvent],
      this.onResolvedThreadVisibilityToggledEvent.bind(this),
      this.owner,
    );
    // this.messageBus.subscribe([SchemeSetEvent], this.onSchemeSetEvent.bind(this), this.owner);
    // this.messageBus.subscribe([FileLoadedEvent], this.onSchemeSetEvent.bind(this), this.owner);
    this.messageBus.subscribe([DevModeToggleEvent], this.onToggleDevModeEvent.bind(this), this.owner);
  }

  private onToolbarStateChangedEvent(event: ToolbarStateChangedEvent) {
    switch (event.params.key) {
      case 'compactMode':
        this.toolbar.setCompactMode(event.params.value as boolean);
        break;
      case 'reset':
        this.toolbar.reset();
        break;
      case 'search':
        this.toolbar.search(event.params.value as string);
        break;
      case 'scheme':
        this.toolbar.changeScheme(event.params.value as string);
        break;
      case 'selectedEntity':
        this.toolbar.selectEntity(event.params.value as string);
        break;
      case 'typeFilter':
        this.toolbar.setFilter(event.params.value as string);
        break;
      case 'close':
        this.toolbar.close();
        break;
      default:
        logger.warn(`Invalid key projecting : ${event.params.key}`);
    }
  }

  private onResolvedThreadVisibilityToggledEvent(event: ResolvedThreadVisibilityToggledEvent) {
    this.toolbar.showResolvedComments(event.params.value);
  }

  private onToggleDevModeEvent(event: DevModeToggleEvent) {
    this.toolbar.updateLayoutOnToggleDevMode();
  }

  // private onSchemeSetEvent(event: FileLoadedEvent | SchemeSetEvent) {
  //   if (event.params.fileExtension) {
  //     const scheme = SchemeRegistry.getSchemeByFileExtension(event.params.fileExtension);
  //     if (scheme) this.toolbar.setDefaultSelectedConstruct(scheme);
  //   }
  // }
}
