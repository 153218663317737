import { ToolbarLayout } from './Toolbar.types';

export const header: ToolbarLayout['header'] = {
  rows: [
    {
      elements: [
        {
          name: 'selectTool',
          type: 'icon-button',
          tooltip: 'Select tool',
          icon: 'cursor',
          iconColor: 'hsl(var(--icon-muted))',
          onClick: {
            command: 'SetToolbarStateCommand',
            params: {
              key: 'selectedEntity',
              value: '',
            },
          },
        },
        {
          type: 'divider',
        },
        {
          name: 'constructSelector',
          type: 'icon-button',
          tooltip: 'Constructs',
          icon: 'rectangle',
          iconColor: '#E0E0E0',
          iconBorder: '#27272A',
          onClick: {
            command: 'SetToolbarStateCommand',
            params: {
              key: 'typeFilter',
              value: 'Construct',
            },
          },
          // onDragStart: {
          //   command: 'SetToolbarStateCommand',
          //   params: {
          //     key: 'selectedEntity',
          //     value: 'Event',
          //   },
          // },
          // onDragEnd: {
          //   command: 'SetToolbarStateCommand',
          //   params: {
          //     key: 'selectedEntity',
          //     value: '',
          //   },
          // },
          more: {
            active: false,
            onClick: {
              command: 'SetToolbarStateCommand',
              params: {
                key: 'typeFilter',
                value: 'Construct',
              },
            },
          },
        },
        {
          name: 'assetSelector',
          type: 'icon-button',
          tooltip: 'Assets',
          icon: 'actor',
          onClick: {
            command: 'SetToolbarStateCommand',
            params: {
              key: 'typeFilter',
              value: 'Asset',
            },
          },
          // onDragStart: {
          //   command: 'SetToolbarStateCommand',
          //   params: {
          //     key: 'selectedEntity',
          //     value: 'Actor',
          //   },
          // },
          // onDragEnd: {
          //   command: 'SetToolbarStateCommand',
          //   params: {
          //     key: 'selectedEntity',
          //     value: '',
          //   },
          // },
          more: {
            active: false,
            onClick: {
              command: 'SetToolbarStateCommand',
              params: {
                key: 'typeFilter',
                value: 'Asset',
              },
            },
          },
        },
        {
          name: 'comment',
          type: 'icon-button',
          tooltip: 'Comment',
          icon: 'comment',
          iconColor: 'hsl(var(--icon))',
          onDragStart: {
            command: 'SetToolbarStateCommand',
            params: {
              key: 'selectedEntity',
              value: 'Thread',
            },
          },
          onDragEnd: {
            command: 'SetToolbarStateCommand',
            params: {
              key: 'selectedEntity',
              value: '',
            },
          },
          more: {
            type: 'menu',
            elements: [
              {
                name: 'showResolvedComments',
                type: 'switch',
                label: 'Show resolved comments',
                value: false,
                onChange: {
                  command: 'ToggleResolvedThreadsVisibilityCommand',
                  params: {
                    value: true,
                  },
                },
              },
            ],
          },
          onClick: {
            command: 'SetToolbarStateCommand',
            params: {
              key: 'selectedEntity',
              value: 'Thread',
            },
          },
        },
        {
          type: 'menu-button',
          tooltip: 'Attachments',
          icon: 'attachment',
          iconColor: 'hsl(var(--icon-muted))',
          menu: [
            {
              type: 'menu-item',
              label: 'My Device',
              icon: 'folder',
              onUpload: {
                command: 'CreateUploadCommand',
                params: {
                  // key: 'selectedEntity',
                  // value: 'upload1',
                },
              },
            },
            {
              type: 'menu-item',
              label: 'From URL',
              icon: 'link',
              onClick: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: 'upload',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};

const origin = typeof window === 'object' ? window.location.origin : '';

export const layout: ToolbarLayout = {
  header: header,
  body: {
    rows: [
      {
        elements: [
          {
            type: 'textbox',
            placeholder: 'Search',
            icon: 'search',
            iconColor: 'hsl(var(--text-secondary))',
            value: '',
            onChange: {
              command: 'SetToolbarStateCommand',
              params: {
                key: 'search',
                value: '',
              },
            },
          },
          {
            type: 'divider',
          },
          {
            type: 'tabs',
            value: 'all',
            tabs: [
              {
                type: 'tab',
                label: 'All',
                value: 'all',
                scheme: '',
                onChange: {
                  command: 'SetToolbarStateCommand',
                  params: {
                    key: 'scheme',
                    value: 'all',
                  },
                },
              },
              // {
              //   type: 'tab',
              //   label: 'NDD',
              //   value: 'ndd',
              //   scheme: 'ndd',
              //   onChange: {
              //     command: 'SetToolbarStateCommand',
              //     params: {
              //       key: 'scheme',
              //       value: 'ndd',
              //     },
              //   },
              // },
              // {
              //   type: 'tab',
              //   label: 'Documentation & Specifications',
              //   value: 'documentation',
              //   scheme: 'documentation',
              //   onChange: {
              //     command: 'SetToolbarStateCommand',
              //     params: {
              //       key: 'scheme',
              //       value: 'documentation',
              //     },
              //   },
              // },
              // {
              //   type: 'tab',
              //   label: 'Event Storming',
              //   value: 'event-storming',
              //   scheme: 'event-storming',
              //   onChange: {
              //     command: 'SetToolbarStateCommand',
              //     params: {
              //       key: 'scheme',
              //       value: 'event-storming',
              //     },
              //   },
              // },
            ],
          },
          {
            type: 'divider',
          },
          {
            name: 'compactMode',
            type: 'switch',
            label: 'Compact mode',
            value: false,
            onChange: {
              command: 'SetToolbarStateCommand',
              params: {
                key: 'compactMode',
                value: true,
              },
            },
          },
          {
            name: 'close',
            type: 'icon-button',
            icon: 'close',
            iconColor: 'hsl(var(--text-primary))',
            style: {
              marginLeft: 'auto',
            },
            onClick: {
              command: 'SetToolbarStateCommand',
              params: {
                key: 'close',
                value: '',
              },
            },
          },
        ],
      },
      {
        elements: [
          // {
          //   type: 'category',
          //   label: 'Narrative Driven Development',
          //   elements: [
          //     {
          //       type: 'category-item',
          //       iconColor: '#E735BF',
          //       shape: 'rectangle',
          //       icon: `${origin}/capability.svg`,
          //       label: 'Capability',
          //       value: 'Capability',
          //       description: "Represents a system's capability",
          //       subtitle: 'NDD',
          //       scheme: 'ndd',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Capability',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Capability',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       icon: `${origin}/narrative.svg`,
          //       label: 'Narrative',
          //       value: 'Narrative',
          //       description: 'Represents a sequence of events and interactions',
          //       subtitle: 'NDD',
          //       scheme: 'ndd',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Narrative',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Narrative',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#8B90FF',
          //       shape: 'rectangle',
          //       icon: `${origin}/moment.svg`,
          //       label: 'Moment',
          //       value: 'Moment',
          //       description: 'Specific moment or interaction within a narrative',
          //       subtitle: 'NDD',
          //       scheme: 'ndd',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Moment',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Moment',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#FFFFFF',
          //       shape: 'rectangle',
          //       icon: `${origin}/interface.svg`,
          //       label: 'Interface',
          //       value: 'Interface',
          //       description: 'UI component or interaction element visible to the user',
          //       subtitle: 'NDD',
          //       scheme: 'ndd',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Interface',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Interface',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#4A4A4A',
          //       shape: 'rectangle',
          //       icon: `${origin}/action.svg`,
          //       label: 'Action',
          //       value: 'Action',
          //       description: 'System-level actions that occur in the background',
          //       subtitle: 'NDD',
          //       scheme: 'ndd',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Action',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Action',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //   ],
          // },
          // {
          //   type: 'category',
          //   label: 'Information Flow',
          //   elements: [
          //     {
          //       type: 'category-item',
          //       iconColor: '#ACD0F7',
          //       shape: 'square',
          //       icon: `${origin}/command.svg`,
          //       label: 'Command',
          //       value: 'Command',
          //       description: 'Input action that starts a business process',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Command',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Command',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#C1E558',
          //       shape: 'square',
          //       icon: `${origin}/data.svg`,
          //       label: 'Data',
          //       value: 'Data',
          //       description: 'Defines the type of data handled in the system',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Data',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Data',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#FF9C48',
          //       shape: 'square',
          //       icon: `${origin}/event.svg`,
          //       label: 'Event',
          //       value: 'Event',
          //       description: 'Signals a system occurrence, like ‘Order Placed’ or ‘Payment Completed.’',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Event',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Event',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //   ],
          // },
          // {
          //   type: 'category',
          //   label: 'Business Process Model',
          //   elements: [
          //     {
          //       type: 'category-item',
          //       iconColor: '#C6A2D2',
          //       shape: 'rectangle',
          //       icon: `${origin}/process.svg`,
          //       label: 'Process',
          //       value: 'Process',
          //       description: 'A sequence of steps to achieve a particular business goal',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Process',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Process',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#FFF9B1',
          //       shape: 'rectangle',
          //       icon: `${origin}/constraints.svg`,
          //       label: 'Constraints',
          //       value: 'Constraints',
          //       description: 'Business rules that dictate conditions for a process to proceed',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Constraints',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Constraints',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#FFD0DF',
          //       shape: 'rectangle',
          //       icon: `${origin}/external-systems.svg`,
          //       label: 'External System',
          //       value: 'ExternalSystem',
          //       description: 'Dependencies outside the core domain',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'ExternalSystem',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'ExternalSystem',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //   ],
          // },
          // {
          //   type: 'category',
          //   label: 'Software Model',
          //   elements: [
          //     {
          //       type: 'category-item',
          //       iconColor: '#9BC916',
          //       shape: 'rectangle',
          //       icon: `${origin}/resolver.svg`,
          //       label: 'Resolver',
          //       value: 'Resolver',
          //       description: 'Processes queries by mapping requests to data sources or computations',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Resolver',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Resolver',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#67C6C0',
          //       shape: 'rectangle',
          //       icon: `${origin}/read-model.svg`,
          //       label: 'Read Model',
          //       value: 'ReadModel',
          //       description: 'Projections of data, optimized for queries and separated from the main data store',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'ReadModel',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'ReadModel',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#FA8A7B',
          //       shape: 'rectangle',
          //       icon: `${origin}/projection.svg`,
          //       label: 'Projection',
          //       value: 'Projection',
          //       description: 'A derived view created from events, used for querying',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Projection',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Projection',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       iconColor: '#F685A9',
          //       shape: 'rectangle',
          //       icon: `${origin}/gateway.svg`,
          //       label: 'Gateway',
          //       value: 'Gateway',
          //       description: 'Mediates between external systems, contexts, or services,',
          //       subtitle: 'Event Storming',
          //       scheme: 'event-storming',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Gateway',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Gateway',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //   ],
          // },
          //{
          //   type: 'category',
          //   label: 'Documentation and Specification',
          //   elements: [
          //     {
          //       type: 'category-item',
          //       icon: `${origin}/spec.svg`,
          //       iconColor: 'rgba(96, 106, 203, 1)',
          //       label: 'Spec',
          //       value: 'Spec',
          //       description: 'Requirements or behavior descriptions',
          //       subtitle: 'Documentation',
          //       scheme: 'documentation',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Spec',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Spec',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       icon: `${origin}/doc.svg`,
          //       iconColor: '#4274A2',
          //       label: 'Doc',
          //       value: 'Doc',
          //       description: 'Rich text documentation, serving as a reference of guide',
          //       subtitle: 'Documentation',
          //       scheme: 'documentation',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Doc',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Doc',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       icon: `${origin}/query.svg`,
          //       iconColor: '#D346A7',
          //       label: 'Query',
          //       value: 'Query',
          //       description: 'Retrieves specific data from a system based on defined criteria',
          //       subtitle: 'Documentation',
          //       scheme: 'documentation',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Query',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Query',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       icon: `${origin}/schema.svg`,
          //       label: 'Schema',
          //       value: 'Schema',
          //       description: 'Defines the shape of the data that will be used',
          //       subtitle: 'Documentation',
          //       scheme: 'documentation',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Schema',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Schema',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //     {
          //       type: 'category-item',
          //       icon: `${origin}/actor.svg`,
          //       label: 'Actor',
          //       value: 'Actor',
          //       description: 'Represents an actor in the system',
          //       subtitle: 'Documentation',
          //       scheme: 'documentation',
          //       onClick: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Actor',
          //         },
          //       },
          //       onDragStart: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: 'Actor',
          //         },
          //       },
          //       onDragEnd: {
          //         command: 'SetToolbarStateCommand',
          //         params: {
          //           key: 'selectedEntity',
          //           value: '',
          //         },
          //       },
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
};
