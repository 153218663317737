import { Explorer } from './read-models/explorer/Explorer';
import { EntityRepository } from './data/EntityRepository';
import { NotificationTypes } from './observable/SingleSourceObserver';
import { Graph } from './read-models/graph/Graph';
import { EntityManager } from './entities/EntityManager';
import { Clipboard } from './clipboard/Clipboard';
import { NavigatorClipboard } from './clipboard/NavigatorClipboard';
import { CanvasInteractor } from './interactors/CanvasInteractor';
import { TransientStore } from './data/Transient';
import { EntitySelectionTracker } from './data/EntitySelectionTracker';
import { ModelFile } from './data/ModelFile';
import { AwarenessTransientStoreProvider } from './data/AwarenessTransientStoreProvider';
import { BoundariesIndex } from './read-models/boundary/BoundariesIndex';
import { EntityDetails } from './read-models/entity-details/EntityDetails';
import { Annotator } from './read-models/annotator/Annotator';
import { Assets } from './read-models/assets/Assets';
import { AssetExplorer } from './read-models/asset-explorer/AssetExplorer';
import { Attachments } from './read-models/attachments/Attachments';
import { ModelSerializer } from './apps/ModelSerializer';
import { ApplicationContext } from './ApplicationContext';
import { ModelYjsAdaptor } from './data/ModelYjsAdaptor';
import { GraphLabelFilters } from './read-models/graph/GraphLabelFilters';
import { DropTargetHighlighterPolicy } from './read-models/graph/DropTargets';
import { ToolbarProjection } from './read-models/toolbar/ToolbarProjection';
import {
  DropTargetsHighlightedEvent,
  HighlightDropTargetsPolicy,
} from './commands/entities/HighlightDropTargetsCommand';
import { EntitiesAddedEvent } from './commands/entities/AddEntityCommand';
import { EntitiesMovedEvent } from './commands/entities/MoveEntitiesCommand';
import { FiltersMenu } from './read-models/filters-menu/FiltersMenu';
import { LabelsInteractor } from './interactors/LabelsInteractor';
import { FiltersInteractor } from './interactors/FiltersInteractor';
import { ThreadInteractor } from './interactors/ThreadInteractor';
import { ScriptInteractor } from './interactors/ScriptInteractor';
import { ChangesSavedEvent } from './commands/changes/BroadcastSavedChangesCommand';
import { IModelContext } from './IModelContext';
import { ModelContextFactory } from './commands/framework/ModelContextProvider';
import { IngestCommandPolicy } from './commands/entities/IngestEntitiesCommand';
import { RecalculateDimensionsPolicy } from './commands/dimensions/RecalculateDimensionsCommand';
import { FollowedUser } from './read-models/followed-user/FollowedUser';
import { ModelSuggestionsProjection } from './read-models/suggestions/ModelSuggestionsProjection';
import { SuggestionsTree } from './read-models/suggestions/SuggestionsTree';

ModelContextFactory.registerFactory(() => ModelContext.getInstance() as IModelContext);

export class ModelContext implements IModelContext {
  private static instance: ModelContext | null;
  modelFile: ModelFile;
  entityRepository: EntityRepository;
  graph: Graph;
  suggestionsTree: SuggestionsTree;
  followedUser: FollowedUser;
  explorer: Explorer;
  entityDetails: EntityDetails;
  annotator: Annotator;
  assets: Assets;
  entities: EntityManager;
  clipboard: Clipboard;
  boundariesIndex: BoundariesIndex;
  interactor: CanvasInteractor;
  isLoaded = false;
  entitySelectionTracker: EntitySelectionTracker;
  assetExplorer: AssetExplorer;
  attachments: Attachments;
  serializer: ModelSerializer;
  adaptor: ModelYjsAdaptor;
  readonly filtersMenu: FiltersMenu;
  readonly labelsInteractor: LabelsInteractor;
  readonly filtersInteractor: FiltersInteractor;
  readonly threadInteractor: ThreadInteractor;
  readonly scriptInteractor: ScriptInteractor;
  private applicationContext = ApplicationContext.getInstance();

  static setActiveModel(modelFile: ModelFile) {
    if (this.instance && modelFile === ModelContext.getInstance().modelFile) {
      return;
    }
    this.instance?.dispose();
    this.instance = new ModelContext(modelFile);
    return this.instance;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('ModelState has no active model. Call ModelState.setActiveModel() first.');
    }
    return this.instance;
  }

  public static hasActiveModel() {
    return this.instance && this.instance.modelFile;
  }

  private constructor(modelFile: ModelFile) {
    if (this.modelFile) {
      this.dispose();
    }
    this.modelFile = modelFile;
    this.adaptor = ModelYjsAdaptor.getInstance(this, modelFile.storageMap, modelFile.settingsMap);
    this.entityRepository = new EntityRepository(
      this.applicationContext.observer,
      this.applicationContext.messageBus,
      modelFile.activeUser,
    );
    this.explorer = new Explorer(this.entityRepository, this.applicationContext.store);
    this.boundariesIndex = new BoundariesIndex(this.entityRepository, this.applicationContext.store);
    this.graph = new Graph(this.entityRepository, this.applicationContext.store, this.boundariesIndex);
    this.suggestionsTree = new SuggestionsTree(this.applicationContext.store);
    this.followedUser = new FollowedUser(this.applicationContext.store, this.applicationContext.fileStoreClient);
    this.entityDetails = new EntityDetails(this.entityRepository, this.applicationContext.store);
    this.annotator = new Annotator(this.entityRepository, this.applicationContext.store);
    this.assets = new Assets(this.entityRepository, this.applicationContext.store);
    this.assetExplorer = new AssetExplorer(this.entityRepository, this.applicationContext.store);
    this.attachments = new Attachments(this.entityRepository, this.applicationContext.store);
    this.entities = new EntityManager(this.entityRepository, this.applicationContext.observer, modelFile.activeUser);
    this.interactor = new CanvasInteractor(this.applicationContext, this);
    this.labelsInteractor = new LabelsInteractor(this, this.applicationContext.messageBus);
    this.filtersInteractor = new FiltersInteractor(this.applicationContext);
    this.threadInteractor = new ThreadInteractor(this.applicationContext);
    this.scriptInteractor = new ScriptInteractor(this.applicationContext);
    this.filtersMenu = new FiltersMenu(this.applicationContext.store);
    this.serializer = new ModelSerializer(this.entityRepository);
    this.entitySelectionTracker = new EntitySelectionTracker(
      this.entityRepository,
      modelFile.awareness!,
      this.applicationContext.observer,
    );
    this.clipboard = new Clipboard(
      new NavigatorClipboard(),
      this.entityRepository,
      this.entitySelectionTracker,
      this.applicationContext.messageBus,
    );
    TransientStore.provider = new AwarenessTransientStoreProvider(modelFile.awareness!, this.entitySelectionTracker);
    modelFile.onAwarenessUpdate((states) => this.entitySelectionTracker.onAwarenessUpdate(states));
    this.projectReadModels();
    this.registerPolicies();
  }

  public dispose() {
    this.clearReadModels();
    if (this.modelFile) {
      this.applicationContext.messageBus.unsubscribeByOwner(this.modelFile.fileId);
      this.applicationContext.observer.unsubscribeByOwner(this.modelFile.fileId);
    }
    ModelContext.instance = null;
    this.modelFile = undefined!;
    this.adaptor.dispose();
    this.entityRepository.dispose();
    this.explorer.dispose();
    this.boundariesIndex.dispose();
    this.graph.dispose();
    this.suggestionsTree.dispose();
    this.entityDetails.dispose();
    this.annotator.dispose();
    this.assets.dispose();
    this.assetExplorer.dispose();
    this.attachments.dispose();
    this.entities.dispose();
    this.interactor.dispose();
    this.adaptor.dispose();
    this.entitySelectionTracker.clearLocalSelections();
    this.clipboard.dispose();
    TransientStore.reset();
  }

  public validateChanges(changes: { added: any[]; updated: any[]; deleted: any[] }): boolean {
    return this.entityRepository.validateChanges(changes);
  }

  public serialize(): { filename: string; content?: any }[] {
    return this.serializer.serialize();
  }

  private clearReadModels() {
    this.graph?.clear();
    this.suggestionsTree?.dispose();
    this.assets?.dispose();
    this.annotator?.dispose();
    this.boundariesIndex?.dispose();
    this.entityDetails?.dispose();
    this.annotator?.dispose();
    this.assetExplorer?.dispose();
    this.explorer?.dispose();
    this.attachments?.dispose();
  }

  private projectReadModels() {
    this.applicationContext.observer.subscribe(
      [NotificationTypes.OnTransientChange, NotificationTypes.OnUserStateChange],
      this.handleObserverUpdate.bind(this),
      this.modelFile.fileId,
    );
    const highlighter = new DropTargetHighlighterPolicy(this.applicationContext);
    this.applicationContext.messageBus.registerEventHandler(
      [DropTargetsHighlightedEvent],
      highlighter,
      this.modelFile.fileId,
    );
    this.applicationContext.messageBus.registerEventHandler(
      [EntitiesAddedEvent, EntitiesMovedEvent],
      highlighter,
      this.modelFile.fileId,
    );

    // UPDATE TOOLBAR READ MODEL
    new ToolbarProjection(this.applicationContext.messageBus, this.applicationContext.toolbar, this.modelFile.fileId);

    // UPDATE MODEL SUGGESTIONS READ MODEL
    new ModelSuggestionsProjection(this.applicationContext.messageBus, this.entityRepository, this.modelFile.fileId);

    this.applicationContext.messageBus.subscribe(
      [ChangesSavedEvent],
      (event: ChangesSavedEvent) => {
        // UPDATE BOUNDARIES READ MODEL
        this.boundariesIndex.updateIndex(event.changes);

        // UPDATE GRAPH READ MODEL
        this.graph.updateGraph(event.changes);

        // UPDATE SUGGESTIONS TREE READ MODEL
        this.suggestionsTree.applyChange(event.changes);

        // UPDATE FILTERS MENU READ MODEL
        this.filtersMenu.update(event.changes);

        // UPDATE ASSETS READ MODEL
        this.assets.update(event.changes);

        // UPDATE ASSET EXPLORER READ MODEL
        this.assetExplorer.update(event.changes);

        // UPDATE EXPLORER READ MODEL
        this.explorer.update(event.changes);
        // UPDATE ATTACHMENTS READ MODEL
        this.attachments.update(event.changes);

        // UPDATE LABEL FILTERS
        new GraphLabelFilters(event.changes, this.applicationContext.store, this.entityRepository).update();

        this.entityDetails.update(event.changes);

        this.annotator.update(event.changes);
      },
      this.modelFile.fileId,
    );
  }

  private registerPolicies() {
    const policies = [IngestCommandPolicy, RecalculateDimensionsPolicy, HighlightDropTargetsPolicy];
    policies.forEach((PolicyClass) => {
      const policyInstance = new PolicyClass(this.applicationContext);
      this.applicationContext.messageBus.registerEventHandler(
        policyInstance.handles(),
        policyInstance,
        this.modelFile.fileId,
      );
    });
  }

  private handleObserverUpdate(changes: any, notificationType?: NotificationTypes) {
    if (notificationType !== NotificationTypes.OnUserStateChange) {
      // UPDATE BOUNDARIES READ MODEL
      this.boundariesIndex.updateIndex(changes);
      // UPDATE GRAPH READ MODEL
      this.graph.updateGraph(changes);
      // UPDATE SUGGESTIONS TREE READ MODEL
      this.suggestionsTree.applyChange(changes);
      // UPDATE FILTERS MENU READ MODEL
      this.filtersMenu.update(changes);
      // UPDATE ASSETS READ MODEL
      this.assets.update(changes);
      // UPDATE ASSET EXPLORER READ MODEL
      this.assetExplorer.update(changes);
      // UPDATE EXPLORER READ MODEL
      this.explorer.update(changes);
      // UPDATE ATTACHMENTS READ MODEL
      this.attachments.update(changes);
    }
    this.entityDetails.update(changes);
    this.annotator.update(changes);
  }
}
