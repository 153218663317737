import {
  ChangeEvent,
  CSSProperties,
  FocusEventHandler,
  forwardRef,
  HTMLProps,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMergedRef, useThrottledCallback } from '@mantine/hooks';
import { DEBUG_CONFIG } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';
import { useApplication } from '../../../../../wrappers/application-context/application-context';

const MIN_FONT_SIZE = 3;
const MAX_FONT_SIZE = 36;

function isSquare(textArea: HTMLTextAreaElement) {
  const style = window.getComputedStyle(textArea);
  return style.width === style.height;
}

function doesTextFit(text: string, textarea: HTMLTextAreaElement, fontSize: number, width: number, height: number) {
  const clone: HTMLTextAreaElement = textarea.cloneNode() as HTMLTextAreaElement;
  clone.style.position = 'absolute';
  clone.style.visibility = 'hidden';
  if (DEBUG_CONFIG.fontResizer) {
    clone.style.position = 'absolute';
    clone.style.backgroundColor = 'black';
    clone.style.color = 'white';
    clone.style.visibility = 'visible';
    clone.style.top = '5000px';
    clone.style.left = '5000px';
  }
  clone.style.width = width + 'px';
  clone.style.height = height + 'px';
  clone.style.fontSize = fontSize + 'px';
  clone.value = text;
  document.body.appendChild(clone);
  const scrollWidth = clone.scrollWidth;
  const scrollHeight = clone.scrollHeight;
  if (DEBUG_CONFIG.fontResizer) setTimeout(() => document.body.removeChild(clone), 500);
  else document.body.removeChild(clone);
  return scrollWidth <= width && scrollHeight <= height;
}

const getFittingFontSize = (
  text: string,
  textarea: HTMLTextAreaElement,
  containerDiv: HTMLDivElement,
  maxFontSize: number,
  minFontSize: number,
) => {
  let newFontSize = maxFontSize;
  while (!doesTextFit(text, textarea, newFontSize, containerDiv.clientWidth, containerDiv.clientHeight))
    if (--newFontSize <= minFontSize) break;
  return newFontSize;
};

function getMaxLength(textArea: HTMLTextAreaElement) {
  return isSquare(textArea) ? 1066 : 1976;
}

type NameProps = {
  id: string;
  value: string;
  onRename: (newName: string) => void;
  onFontSizeChange: (fontSize: number) => void;
  isSelected: boolean;
  isNew: boolean;
  isDragging: boolean;
  fontSize: number;
  onClick?: () => void;
} & Omit<HTMLProps<HTMLDivElement>, 'ref' | 'data'>;

export const Name = forwardRef<HTMLDivElement, NameProps>((props, ref) => {
  const { id, value, onRename, onFontSizeChange, isSelected, isNew, isDragging, onClick, ...rest } = props;

  const { application } = useApplication();

  const [newName, setNewName] = useState(value);
  const [textArea, setTextArea] = useState<HTMLTextAreaElement>();
  const [height, setHeight] = useState<number>(0);
  const [fontSize, setFontSize] = useState<number>(MAX_FONT_SIZE);
  const [clickCount, setClickCount] = useState(0);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const isFirstLoad = useRef(true);
  const shouldResizeBecauseLocal = useRef(false);

  const throttledOnRename = useThrottledCallback((value: string) => onRename(value), 1000);

  const setRef = useCallback(
    (node: HTMLDivElement) => {
      containerRef.current = node;
      if (node !== null) {
        if (isDragging) {
          textArea?.blur();
          setClickCount(0);
        }
      }
    },
    [textArea, isDragging, setClickCount],
  );

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const resizeTextarea = useCallback(
    (containerDiv: HTMLDivElement) => {
      if (!textArea) return;
      if (textArea.scrollHeight > containerDiv.clientHeight) setHeight(containerDiv.clientHeight);
      else setHeight(textArea.scrollHeight);
    },
    [textArea],
  );

  const fontSizeAttribute = props.fontSize;

  const getFontSize = useCallback(
    (text: string): [number, boolean] => {
      if (!textArea || !containerRef.current) return [MAX_FONT_SIZE, false];
      if (isFirstLoad.current) {
        isFirstLoad.current = false;
        if (fontSizeAttribute && fontSizeAttribute !== -1) {
          // on first load, use the font size from the entity if it exists
          if (DEBUG_CONFIG.fontResizer) logger.log('name.tsx: First load and cache hit');
          return [fontSizeAttribute, false];
        } else {
          // otherwise on first load, calculate the font size
          if (DEBUG_CONFIG.fontResizer) logger.log('name.tsx: First load and cache miss - calculating');
          const fontSize = getFittingFontSize(text, textArea, containerRef.current, MAX_FONT_SIZE, MIN_FONT_SIZE);
          return [fontSize, true];
        }
      } else if (shouldResizeBecauseLocal.current || fontSizeAttribute === -1) {
        // if the text has changed locally, recalculate the font size
        shouldResizeBecauseLocal.current = false;
        if (DEBUG_CONFIG.fontResizer) logger.log('name.tsx: Subsequent load, local change');
        const fontSize = getFittingFontSize(text, textArea, containerRef.current, MAX_FONT_SIZE, MIN_FONT_SIZE);
        return [fontSize, true];
      } else {
        // if the font size has changed remotely, use that value
        if (DEBUG_CONFIG.fontResizer) logger.log('name.tsx: Subsequent load, using remotely changed font size');
        return [fontSizeAttribute, false];
      }
    },
    [fontSizeAttribute, textArea],
  );

  useEffect(() => {
    if (!textArea || !containerRef.current) return;

    const [fittingFontSize, saveInModel] = getFontSize(value);
    setFontSize(fittingFontSize);
    if (saveInModel) onFontSizeChange(fittingFontSize);
    resizeTextarea(containerRef.current);
  }, [getFontSize, onFontSizeChange, resizeTextarea, textArea, value]);

  const handleMouseDownCapture = useCallback(() => {
    if (clickCount === 0 && isSelected) {
      setClickCount(2);
      textAreaRef.current?.focus();
    } else {
      setClickCount((prev) => prev + 1);
    }
  }, [clickCount, isSelected, setClickCount]);

  const onContainerBlur = useCallback<FocusEventHandler<HTMLDivElement>>(
    (event) => {
      if (containerRef.current?.contains(event.relatedTarget)) {
        return;
      }
      if (clickCount > 0) {
        setClickCount(0);
        application?.getModelContext()?.entities.clearNewState(id);
      }
    },
    [application, clickCount, id],
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newName = event.target.value;
      if (!textArea || !containerRef.current) return;
      setNewName(newName);
      if (newName.length <= getMaxLength(textArea)) {
        const result = getFittingFontSize(newName, textArea, containerRef.current, MAX_FONT_SIZE, MIN_FONT_SIZE);
        setFontSize(result);
        resizeTextarea(containerRef.current);
      }
      throttledOnRename(newName);
    },
    [resizeTextarea, textArea, throttledOnRename],
  );

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>((event) => {
    if (event.key === 'Escape') {
      setClickCount(0);
      textAreaRef.current?.blur();
    }
  }, []);

  useEffect(() => {
    if (textAreaRef.current) {
      setTextArea(textAreaRef.current);
      if (isNew) {
        setClickCount(2);
        textAreaRef.current.focus();
      }
    }
  }, [isNew, setTextArea]);

  useEffect(() => {
    if (isDragging) {
      setClickCount(0);
    }
  }, [isDragging]);

  const style = useMemo(
    () =>
      ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100% - 8px)',
        width: 'calc(100% - 8px)',
        position: 'absolute',
        outline: 'none',
        zIndex: 1,
        top: 4,
        left: 4,
        color: 'inherit',
        borderRadius: 6,
      }) as CSSProperties,
    [],
  );

  const overlayStyles = useMemo(
    () =>
      ({
        display: clickCount > 0 ? 'none' : 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        color: 'inherit',
      }) as CSSProperties,
    [clickCount],
  );
  const textAreaStyle = useMemo(
    () =>
      ({
        border: 'none',
        width: '100%',
        height,
        resize: 'none',
        textAlign: 'center',
        outline: 'none',
        padding: 5,
        overflow: 'hidden',
        cursor: isTextAreaFocused ? 'text' : 'grab',
        background: 'none',
        lineHeight: '1.1',
        fontFamily: 'HK Grotesk, sans-serif',
        fontSize,
        whiteSpace: 'normal',
        wordWrap: 'normal',
        color: 'inherit',
      }) as CSSProperties,
    [fontSize, height, isTextAreaFocused],
  );

  const onBlur = useCallback(() => {
    setIsTextAreaFocused(false);
    setNewName(value);
  }, [value]);
  const onFocus = useCallback(() => setIsTextAreaFocused(true), []);

  const rootRef = useMergedRef(ref, setRef);

  useEffect(() => {
    if (!isTextAreaFocused) setNewName(value);
  }, [isTextAreaFocused, value]);

  return (
    <div ref={rootRef} onBlur={onContainerBlur} tabIndex={1} style={style} onClick={onClick} {...rest}>
      <div style={overlayStyles} onMouseDownCapture={handleMouseDownCapture} />
      <textarea
        ref={textAreaRef}
        value={newName}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        className={isTextAreaFocused ? 'nodrag' : undefined}
        style={textAreaStyle}
      />
    </div>
  );
});
Name.displayName = 'Name';
