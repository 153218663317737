import { EntityType } from './EntityType';
import { Attachment } from './assets/Attachment';
import { Actor } from './assets/Actor';
import { Spec } from './assets/Spec';
import { Doc } from './assets/Doc';
import { Query } from './assets/Query';
import { Thread } from './threads/Thread';
import { Comment } from './threads/Comment';
import { Label } from './assets/Label';
import { Filter } from './Filter';
import { Edge } from './transitions/Edge';
import { Upload } from './assets/Upload';
import { Preview } from './assets/Preview';
import { GqlOperation } from './gql-entities/GqlOperation';
import { GqlField } from './gql-entities/GqlField';
import { EntityBase } from './EntityBase';

type EntityBaseConstructor = new (...args: any[]) => EntityBase;

export class EntityClassRegistry {
  private static registry: Partial<Record<EntityType, EntityBaseConstructor>>;
  private static initialized = false;

  private static initializeEntityClassRegistry() {
    if (!this.initialized) {
      this.registry = {
        [EntityType.Attachment]: Attachment,
        [EntityType.Actor]: Actor,
        [EntityType.Spec]: Spec,
        [EntityType.Doc]: Doc,
        [EntityType.Query]: Query,
        [EntityType.Edge]: Edge,
        [EntityType.Thread]: Thread,
        [EntityType.Comment]: Comment,
        [EntityType.Upload]: Upload,
        [EntityType.Label]: Label,
        [EntityType.Filter]: Filter,
        [EntityType.Preview]: Preview,
        [EntityType.GqlOperation]: GqlOperation,
        [EntityType.GqlField]: GqlField,
      };
      this.initialized = true;
    }
  }

  public static register(type: EntityType | string, constructor: EntityBaseConstructor): void {
    this.initializeEntityClassRegistry();
    this.registry[type] = constructor;
  }

  public static deregister(type: string): void {
    this.initializeEntityClassRegistry();
    delete this.registry[type];
  }

  public static reset(): void {
    this.initialized = false;
    this.initializeEntityClassRegistry();
  }

  public static entries() {
    return this.registry;
  }

  public static getConstructor(type: string) {
    this.initializeEntityClassRegistry();
    return this.registry[type];
  }
}
