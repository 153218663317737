import { EntityType } from '@xspecs/single-source-model';
import { NodeProps, NodeTypes } from '@xyflow/react';
import { ComponentType } from 'react';

const RECTANGLE_ASPECT_RATIO = 1.75 / 1;
const SQUARE_ASPECT_RATIO = 1 / 1;

export const CONSTRUCTS_ASPECT_RATIO = {
  [EntityType.Command]: SQUARE_ASPECT_RATIO,
  [EntityType.Action]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Constraints]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Data]: SQUARE_ASPECT_RATIO,
  [EntityType.Event]: SQUARE_ASPECT_RATIO,
  [EntityType.ExternalSystem]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Gateway]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Interface]: RECTANGLE_ASPECT_RATIO,
  ['Moment']: RECTANGLE_ASPECT_RATIO,
  [EntityType.Process]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Projection]: RECTANGLE_ASPECT_RATIO,
  [EntityType.ReadModel]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Resolver]: RECTANGLE_ASPECT_RATIO,
};

export const wrapNodesWithHoc = (
  nodes: NodeTypes,
  hoc: (node: ComponentType<NodeProps>) => ComponentType<NodeProps>,
  keysToExclude: string[] = [],
) =>
  Object.keys(nodes).reduce((acc, key) => {
    acc[key] = keysToExclude.includes(key) ? nodes[key] : hoc(nodes[key]);
    return acc;
  }, {} as NodeTypes);

const FIGMA_URL_REGEX = /https:\/\/[\w.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;

export const isValidFigmaEmbedUrl = (url: string) => {
  return FIGMA_URL_REGEX.test(url);
};

export const formatFigmaUrlForEmbed = (url: string) => {
  const host = window.location.hostname;

  return url
    .replace(/(www\.)?(figma\.com)/, 'embed.figma.com')
    .concat(url.includes('?') ? '&' : '?', `embed-host=${host}`);
};

export const extractFigmaFileName = (url: string) => {
  const match = url.match(/\/([^/?]+)(?:\?|$)/);
  if (match) {
    const decoded = decodeURIComponent(match[1]);
    return decoded.replace(/-/g, ' ');
  }
  return null;
};
