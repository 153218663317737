import {
  ApplicationContext,
  AssetScope,
  AssetSort,
  AttachmentType,
  DEFAULT_FILTERS_MENU_STATE,
  DEFAULT_SUGGESTIONS_TREE_STATE,
  EntityType,
  ExplorerSort,
  Mode,
  SingleSourceStore,
  TOOLBAR_DEFAULT_STATE,
  UITheme,
} from '@xspecs/single-source-model';
import { Viewport } from '@xyflow/react';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { createSelectors } from '../../common/zustand';
import { immer } from 'zustand/middleware/immer';

const singleSourceStore = create<SingleSourceStore>()(
  subscribeWithSelector(
    immer(
      persist(
        (set) => ({
          assets: {
            [AttachmentType.Upload]: [],
            [AttachmentType.Actor]: [],
            [AttachmentType.Doc]: [],
            [AttachmentType.Query]: [],
            ['Schema']: [],
            [AttachmentType.Spec]: [],
          },
          assetExplorer: {
            results: [],
            searchQuery: '',
            sortQuery: AssetSort.Newest,
            scope: AssetScope.Current,
          },
          explorer: {
            results: [],
            searchQuery: '',
            sortQuery: ExplorerSort.Newest,
          },
          instantMockUrl: 'http://localhost:3001/api',
          mode: Mode.SELECTION,
          constructToInsert: null,
          constructToInsertUsingDrag: null,
          activeUsersByFile: {},
          constructToPanTo: undefined,
          singleSourceModel: null,
          searchQuery: '',
          expandAll: false,
          collapseAll: false,
          showResolvedThreads: false,
          annotator: null,
          isLoaded: false,
          showUploadFileModal: undefined,
          showPreviewFor: undefined,
          states: {},
          graph: { nodes: [], edges: [] },
          filesById: {},
          labels: [],
          showManageLabelsModal: false,
          unsavedFilter: undefined,
          savedFilters: [],
          appliedSavedFilter: undefined,
          openTagsSelectorFor: undefined,
          showRestorePreviousVersionModal: false,
          entityDetails: null,
          errors: [],
          showUploadLinkModal: false,
          urlToPreview: null,
          showProposeSchemaChangesModal: false,
          viewports: {},
          selectedGraphs: {},
          variantProposal: '',
          graphDetails: null,
          loadingVariantsProposal: false,
          schemaOptions: [],
          creatingProposal: false,
          showKeyboardShortcutsModal: false,
          markers: {},
          runningQuery: false,
          seedData: {},
          showWorkspaceResetModal: false,
          workspace: null,
          organization: null,
          showUploadAppModal: false,
          palette: undefined,
          showDeleteAssetModal: false,
          assetToDelete: undefined,
          collapseSidebar: true,
          filtersMenu: DEFAULT_FILTERS_MENU_STATE,
          attachmentsByAssetId: {},
          toolbar: TOOLBAR_DEFAULT_STATE,
          showCreateSpaceModal: false,
          spaceToRename: undefined,
          spaceToDelete: undefined,
          recentSpacesByOrg: {},
          fileTree: {
            rootId: 'rootId',
            items: [],
            addItemsMenu: [],
            onRenameItem: {
              command: 'RenameFileTreeItemCommand',
            },
            onDeleteItem: {
              command: 'DeleteFileTreeItem',
            },
          },
          suggestionsTree: {
            ...DEFAULT_SUGGESTIONS_TREE_STATE,
          },
          showMouseTrackpadSettings: false,
          mouseInputMode: 'trackpad',
          ide: {
            panels: [],
            selectedFile: undefined,
          },
          buildLog: undefined,
          devMode: false,
          panels: {
            'ide-sidebar': { collapsed: false, compact: false, size: 15 },
            logs: { collapsed: true, compact: false, size: 0, follow: true },
            details: { collapsed: true, compact: false, size: 15 },
            toolbar: { collapsed: true, compact: false, size: 0 },
          },
          followedUser: null,
          showChatForModel: undefined,
          theme: UITheme.LIGHT,

          setSuggestionsTree: (suggestionsTree) => {
            set({ suggestionsTree });
          },
          setFollowedUser: (followedUser) => {
            set({ followedUser });
          },
          setBuildLog: (buildLogState) => {
            set({ buildLog: buildLogState });
          },
          setToolbar: (toolbar) => {
            set({ toolbar });
          },
          setShowProposeSchemaChangesModal: (showProposeSchemaChangesModal) => {
            set({ showProposeSchemaChangesModal });
          },
          setSelectionMode: () => {
            set({ mode: Mode.SELECTION, constructToInsert: null });
          },
          setInsertConstructMode: () => {
            set({ mode: Mode.INSERT_CONSTRUCT });
          },
          setInsertCapabilityMode: () => {
            set({ mode: Mode.INSERT_CAPABILITY, constructToInsert: null });
          },
          setInsertMomentMode: () => {
            set({ mode: Mode.INSERT_MOMENT, constructToInsert: null });
          },
          setInsertActionMode: () => {
            set({ mode: Mode.INSERT_ACTION, constructToInsert: null });
          },
          setInsertInterfaceMode: () => {
            set({ mode: Mode.INSERT_INTERFACE, constructToInsert: null });
          },
          setInsertActorMode: () => {
            set({ mode: Mode.INSERT_ACTOR, constructToInsert: null });
          },
          setInsertSpecMode: () => {
            set({ mode: Mode.INSERT_SPEC, constructToInsert: null });
          },
          setInsertThreadMode: () => {
            set({ mode: Mode.INSERT_THREAD, constructToInsert: null });
          },
          setConstructToInsert: (construct) => {
            set({ mode: Mode.INSERT_CONSTRUCT, constructToInsert: construct });
          },
          setActiveUsersByFile: (fileId, activeUsers) => {
            set((state) => ({
              activeUsersByFile: { ...state.activeUsersByFile, [fileId]: activeUsers },
            }));
          },
          setInsertNarrativeMode: () => {
            set({ mode: Mode.INSERT_SCRIPT });
          },
          setConstructToPanTo: (id) => {
            set({ constructToPanTo: id });
          },
          setSingleSourceModel: (model: ApplicationContext) => {
            set({ singleSourceModel: model });
          },
          setAnnotator: (annotator) => {
            set((state) => ({ annotator: annotator ? { ...state.annotator, ...annotator } : null }));
          },
          setSearchQuery: (searchQuery) => {
            set({ searchQuery });
          },
          setShowResolvedThreads: (showResolvedThreads) => {
            set({ showResolvedThreads });
          },
          setIsLoaded: (isLoaded) => {
            set({ isLoaded });
          },
          setDevMode: (devMode) => {
            set({ devMode });
          },
          setConstructToInsertUsingDrag: (constructToInsertUsingDrag: EntityType) => {
            set({ constructToInsertUsingDrag });
          },
          setShowUploadFileModal: (showUploadFileModal?: string) => {
            set({ showUploadFileModal });
          },
          setStateField: (key, value) => {
            set((state) => ({
              states: { ...state.states, [key]: value },
            }));
          },
          setGraph: (graph) => {
            set({ graph });
          },
          setFileById: (fileId, file) => {
            set((state) => ({
              filesById: { ...state.filesById, [fileId]: file },
            }));
          },
          setLabels: (labels) => {
            set({ labels });
          },
          setShowManageLabelsModal: (showManageLabelsModal) => {
            set({ showManageLabelsModal });
          },
          setUnsavedFilter: (unsavedFilter) => {
            set({ unsavedFilter });
          },
          setSavedFilters: (savedFilters) => {
            set({ savedFilters });
          },
          setAppliedSavedFilter: (appliedSavedFilter) => {
            set({ appliedSavedFilter });
          },
          setShowRestorePreviousVersionModal: (showRestorePreviousVersionModal) => {
            set({ showRestorePreviousVersionModal });
          },
          setEntityDetails: (entityDetails) => {
            set({ entityDetails });
          },
          setErrors: (errors) => {
            set({ errors: errors });
          },
          setShowUploadLinkModal: (showUploadLinkModal) => {
            set({ showUploadLinkModal });
          },
          setUrlToPreview: (urlToPreview) => {
            set({ urlToPreview });
          },
          setViewports: (key: string, viewport: Viewport) => {
            set((state) => ({
              viewports: { ...state.viewports, [key]: viewport },
            }));
          },
          setInstantMockUrl: (instantMockUrl) => {
            set({ instantMockUrl: instantMockUrl.toLowerCase() });
          },
          setSelectedGraphs: (params) => {
            set((state) => {
              if (params.selectedGraph) {
                state.selectedGraphs[params.queryId] = params.selectedGraph;
              } else {
                delete state.selectedGraphs[params.queryId];
              }
            });
          },
          setVariantProposal: (variantProposal) => {
            set({ variantProposal });
          },
          setGraphDetails: (graphDetails) => {
            set({ graphDetails });
          },
          setLoadingVariantsProposal: (loadingVariantsProposal) => {
            set({ loadingVariantsProposal });
          },
          setAssets: (assets) => {
            set({ assets });
          },
          setAssetExplorer: (assetExplorer) => {
            set({ assetExplorer });
          },
          setSchemaOptions: (schemaOptions) => {
            set({ schemaOptions });
          },
          setCreatingProposal: (creatingProposal) => {
            set({ creatingProposal });
          },
          setShowKeyboardShortcutModal: (showKeyboardShortcutsModal) => {
            set({ showKeyboardShortcutsModal });
          },
          setMarkers: ({ id, marker }) => {
            set((state) => ({
              markers: { ...state.markers, [id]: marker },
            }));
          },
          setRunningQuery: (runningQuery) => {
            set({ runningQuery });
          },
          setSeedData: (seedData = {}) => {
            set({ seedData });
          },
          setShowWorkspaceResetModal: (showWorkspaceResetModal) => {
            set({ showWorkspaceResetModal });
          },
          setOrganization: (organization) => {
            set({ organization });
          },
          setWorkspace: (workspace) => {
            set({ workspace });
          },
          setShowUploadAppModal: (showUploadAppModal) => {
            set({ showUploadAppModal });
          },
          setAppState: (appName, state) => {
            set({ [appName]: state });
          },
          setPalette: (palette) => {
            set({ palette });
          },
          setShowDeleteAssetModal: (showDeleteAssetModal) => {
            set({ showDeleteAssetModal });
          },
          setAssetToDelete: (assetToDelete) => {
            set({ assetToDelete });
          },
          setCollapseSidebar: (collapseSidebar) => {
            set({ collapseSidebar });
          },
          setState(storePath, value) {
            set((state) => {
              createNestedPath(state, storePath, value);
            });
          },
          setFiltersMenu: (filtersMenu) => {
            set({ filtersMenu });
          },
          setExplorer: (explorer) => {
            set({ explorer });
          },
          setAttachmentsByAssetId: (attachmentsByAssetId) => {
            set({ attachmentsByAssetId });
          },
          setShowCreateSpaceModal: (showCreateSpaceModal) => {
            set({ showCreateSpaceModal });
          },
          setSpaceToRename: (spaceToRename) => {
            set({ spaceToRename });
          },
          setSpaceToDelete: (spaceToDelete) => {
            set({ spaceToDelete });
          },
          setRecentSpacesByOrg: (orgId, workspace) => {
            set((state) => {
              const recentSpacesByOrg = { ...state.recentSpacesByOrg };
              if (!recentSpacesByOrg[orgId]) {
                recentSpacesByOrg[orgId] = [];
              }
              recentSpacesByOrg[orgId] = recentSpacesByOrg[orgId].filter((ws) => ws.id !== workspace.id);
              recentSpacesByOrg[orgId].unshift(workspace);
              return { recentSpacesByOrg };
            });
          },
          setFileTree: (fileTree) => {
            set({ fileTree });
          },
          setShowMouseTrackpadSettings: (showMouseTrackpadSettings) => {
            set({ showMouseTrackpadSettings });
          },
          setMouseInputMode: (mouseInputMode) => {
            set({ mouseInputMode });
          },
          setIde: (ide) => {
            set({ ide });
          },
          setPanels: (name, key, value) => {
            set((state) => ({
              panels: {
                ...state.panels,
                [name]: { ...state.panels[name], [key]: value },
              },
            }));
          },
          setShowChatForModel: (showChatForModel) => {
            set({ showChatForModel });
          },
          setTheme: (theme) => {
            set({ theme });
          },
        }),
        {
          name: 'single-source-store',
          partialize: (state) => ({
            states: state.states,
            viewports: state.viewports,
            instantMockUrl: state.instantMockUrl,
            collapseSidebar: state.collapseSidebar,
            selectedGraphs: state.selectedGraphs,
            variantProposal: state.variantProposal,
            recentSpacesByOrg: state.recentSpacesByOrg,
            mouseInputMode: state.mouseInputMode,
            panels: state.panels,
            toolbar: state.toolbar,
            theme: state.theme,
            devMode: state.devMode,
          }),
        },
      ),
    ),
  ),
);

const useSingleSourceStore = createSelectors(singleSourceStore);
export { singleSourceStore, useSingleSourceStore };

function createNestedPath(obj: object, path: string, value: unknown) {
  const keys = path.split('.');
  let current = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (i === keys.length - 1) {
      current[key] = value;
    } else {
      if (!current[key] || typeof current[key] !== 'object') {
        current[key] = {};
      }

      current = current[key];
    }
  }
}
