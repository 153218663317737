import {
  AllowedAction,
  Construct,
  ConstructShape,
  FileType,
  ISchemeProvider,
  Scheme,
  SchemeBuilder,
} from 'narrative-studio-sdk';

export class EventModelingSchemeProvider implements ISchemeProvider {
  getScheme(): Scheme {
    const origin = `${globalThis.origin || 'http://localhost'}`;
    const ui = {
      type: 'UI',
      label: 'UI',
      description: 'Represents a user interface where users interact with the system to initiate actions.',
      style: { backgroundColor: '#FFFFFF', textColor: '#000000', borderColor: '#000000', borderWidth: 1 },
      shape: ConstructShape.RECTANGLE,
      fileConfig: { type: FileType.JSON, defaultValue: '{}' },
    } satisfies Construct;
    const api = {
      type: 'API',
      label: 'API',
      description: 'Represents an API where systems interact with the system to initiate actions.',
      style: { backgroundColor: '#999999', textColor: '#000000' },
      shape: ConstructShape.RECTANGLE,
      fileConfig: { type: FileType.JSON, defaultValue: '{}' },
    } satisfies Construct;
    const processor = {
      type: 'Processor',
      label: 'Processor',
      description: 'Defines a business processor or workflow, responsible for handling TODO lists or translations.',
      style: { backgroundColor: '#333333', textColor: 'white' },
      shape: ConstructShape.RECTANGLE,
      fileConfig: { type: FileType.JSON, defaultValue: '{}' },
    } satisfies Construct;
    const event = {
      type: 'Event',
      label: 'Event',
      description: 'Captures a significant occurrence or fact in the system that has business relevance.',
      style: { backgroundColor: '#FF9C48', textColor: '#000000' },
      shape: ConstructShape.SQUARE,
      fileConfig: { type: FileType.JSON, defaultValue: '{}' },
    } satisfies Construct;
    const state = {
      type: 'State',
      label: 'State',
      description: 'Represents the current state of an entity as a result of past events.',
      style: { backgroundColor: '#C1E558', textColor: '#000000' },
      shape: ConstructShape.SQUARE,
      fileConfig: { type: FileType.JSON, defaultValue: '{}' },
    } satisfies Construct;
    const command = {
      type: 'Command',
      label: 'Command',
      description: 'Expresses an intent or action initiated by a user or external system.',
      style: { backgroundColor: '#ACD0F7', textColor: '#000000' },
      shape: ConstructShape.SQUARE,
      fileConfig: { type: FileType.JSON, defaultValue: '{}' },
    } satisfies Construct;
    const externalEvent = {
      type: 'ExternalEvent',
      label: 'External Event',
      description: 'Represents an event external to the domain that interacts with it, such as third-party APIs.',
      style: { backgroundColor: '#FFD0DF', textColor: '#000000' },
      shape: ConstructShape.SQUARE,
      fileConfig: { type: FileType.JSON, defaultValue: 'Given' },
    } satisfies Construct;
    const gwt = {
      type: 'GWT',
      label: 'GWT',
      description: 'Given-When-Then scenario',
      style: { backgroundColor: '#ffffff', textColor: '#000000', borderColor: '#C1E558', borderWidth: 3 },
      shape: ConstructShape.RECTANGLE,
      fileConfig: { type: FileType.GHERKIN },
    } satisfies Construct;
    return SchemeBuilder.create({ name: 'Event Modeling', fileExtension: 'em', defaultConstruct: 'EventModel' })
      .addCategory('Event Modeling')
      .addConstruct({
        type: 'EventModel',
        label: 'Event Model',
        description: 'The overarching model that represents the interactions and flow within the system.',
        shape: ConstructShape.RECTANGLE,
        style: { backgroundColor: '#ffffff', textColor: '#000000', borderColor: '#FF9C48', borderWidth: 3 },
        icon: `${origin}/eventmodel.svg`,
      })
      .addScript({
        type: 'EventModelScript',
      })
      .addFrameGroup({
        allowedActions: { actions: [AllowedAction.NONE] },
        frameGroupLimits: { min: 1, max: 1 },
        frameLimits: { min: 2, max: Infinity },
        defaultFrameWidth: 200,
      })
      .addFrame({})
      .addFrameGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.REMOVE, AllowedAction.UPDATE] },
        style: { borderWidth: 3, borderColor: '#D4D4D8' },
        darkModeStyle: { borderWidth: 3, borderColor: '#71717A' },
        frameGroupLimits: { min: 1, max: Infinity },
        frameLimits: { min: 1, max: Infinity },
        defaultFrameWidth: 200,
        label: {
          text: 'State Change',
          fontSize: 20,
          backgroundColor: '#ACD0F7',
          alignment: 'left',
        },
        defaultType: 'state-change',
        typeSelectMenu: [
          {
            // typeOption
            text: 'State Change',
            icon: 'state-change',
            frameGroupLabel: {
              text: 'State Change',
              backgroundColor: '#ACD0F7',
              alignment: 'left',
              fontSize: 24,
            },
            type: 'state-change',
          },
          {
            text: 'State View',
            icon: 'state-view',
            frameGroupLabel: {
              text: 'State View',
              backgroundColor: '#C1E558',
              alignment: 'left',
              fontSize: 24,
            },
            type: 'State View',
          },
          {
            text: 'Automation',
            icon: 'automation',
            frameGroupLabel: {
              text: 'Automation',
              backgroundColor: '#4A4A4A',
              alignment: 'left',
              fontSize: 24,
              textColor: 'white',
            },
            type: 'automation',
          },
          {
            text: 'Translation',
            icon: 'translation',
            frameGroupLabel: {
              text: 'Translation',
              backgroundColor: '#F685A9',
              alignment: 'left',
              fontSize: 24,
            },
            type: 'translation',
          },
          {
            text: 'Freeform',
            icon: 'grid3x3',
            frameGroupLabel: {
              text: 'Freeform',
              backgroundColor: 'grey',
              textColor: 'white',
              alignment: 'left',
              fontSize: 24,
            },
            type: 'freeform',
          },
        ],
      })
      .addFrame({})
      .addFrame({})
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.UPDATE, AllowedAction.REMOVE] },
        laneGroupLimits: { min: 1, max: 1 },
        label: { text: 'UI/API', alignment: 'center', fontSize: 24 },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: { type: 'SPECIFIC', entities: [ui, api] },
        defaultLaneHeight: 200,
        laneAlignmentFrameIndex: 1,
        style: { backgroundColor: '#FFFFFF', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#3C3F41', textColor: '#FAFAFA' },
      })
      .addLane({
        style: { backgroundColor: '#FFFFFF', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#3C3F41', textColor: '#FAFAFA' },
      })
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.REMOVE] },
        laneGroupLimits: { min: 1, max: 1 },
        label: { text: 'Processors', alignment: 'center', fontSize: 24 },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: { type: 'SPECIFIC', entities: [processor] },
        style: { backgroundColor: '#F5F5F0', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#38393D', textColor: '#FAFAFA' },
        defaultLaneHeight: 200,
        laneAlignmentFrameIndex: 1,
      })
      .addLane({
        style: { backgroundColor: '#F5F5F0', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#38393D', textColor: '#FAFAFA' },
      })
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.NONE] },
        laneGroupLimits: { min: 1, max: 1 },
        label: { text: 'I/O', alignment: 'center', fontSize: 24 },
        style: { backgroundColor: '#E0F2FE', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#2C4B5F', textColor: '#FAFAFA' },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: { type: 'SPECIFIC', entities: [command, state] },
        defaultLaneHeight: 200,
        laneAlignmentFrameIndex: 1,
      })
      .addLane({
        style: { backgroundColor: '#E0F2FE', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#2C4B5F', textColor: '#FAFAFA' },
      })
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.UPDATE, AllowedAction.REMOVE] },
        laneGroupLimits: { min: 1, max: 1 },
        label: { text: 'Domain Events', alignment: 'center', fontSize: 24 },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: { type: 'SPECIFIC', entities: [event] },
        style: { backgroundColor: '#FEF3C7', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#4D4028', textColor: '#FAFAFA' },
        defaultLaneHeight: 200,
        laneAlignmentFrameIndex: 1,
      })
      .addLane({
        style: { backgroundColor: '#FEF3C7', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#4D4028', textColor: '#FAFAFA' },
      })
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.UPDATE, AllowedAction.REMOVE] },
        laneGroupLimits: { min: 1, max: 1 },
        label: { text: 'External Events', alignment: 'center', fontSize: 24 },
        style: { backgroundColor: '#F0F0F0', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#3A3C42', textColor: '#FAFAFA' },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: { type: 'SPECIFIC', entities: [externalEvent] },
        defaultLaneHeight: 200,
        laneAlignmentFrameIndex: 1,
      })
      .addLane({
        style: { backgroundColor: '#F0F0F0', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#3A3C42', textColor: '#FAFAFA' },
      })
      .addLaneGroup({
        allowedActions: { actions: [AllowedAction.ADD, AllowedAction.REMOVE] },
        laneGroupLimits: { min: 1, max: 1 },
        label: { text: 'GWT Specs', alignment: 'center', fontSize: 24 },
        style: { backgroundColor: '#ECFCCB', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#303D28', textColor: '#FAFAFA' },
        laneLimits: { min: 1, max: Infinity },
        allowedEntities: { type: 'SPECIFIC', entities: [gwt] },
        defaultLaneHeight: 200,
        laneAlignmentFrameIndex: 1,
      })
      .addLane({
        style: { backgroundColor: '#ECFCCB', textColor: '#18181B' },
        darkModeStyle: { backgroundColor: '#303D28', textColor: '#FAFAFA' },
      })
      .addConstruct(event)
      .addConstruct(command)
      .addConstruct(state)
      .addConstruct(processor)
      .addConstruct(ui)
      .addConstruct(api)
      .addConstruct(externalEvent)
      .addConstruct(gwt)
      .addCategory('Specifications')
      .addAsset({
        type: 'Actor',
        label: 'Actor',
        icon: `${origin}/actor.svg`,
        description: 'Represents an actor in the system',
        fileConfig: {
          type: FileType.SLATE,
        },
      })
      .addAsset({
        type: 'Spec',
        label: 'Spec',
        description: 'Requirements or behavior descriptions',
        icon: `${origin}/spec.svg`,
        fileConfig: {
          type: FileType.SPEC,
        },
      })
      .addAsset({
        type: 'Doc',
        label: 'Doc',
        icon: `${origin}/doc.svg`,
        description: 'Rich text documentation, serving as a reference of guide',
        fileConfig: {
          type: FileType.SLATE,
        },
      })
      .build();
  }
}
