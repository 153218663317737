import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { Edge, strToHandleLocation } from '../../entities/transitions/Edge';
import { sid } from '@xspecs/short-id';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';

interface AddRelationshipParams extends IParams {
  sourceId: string;
  targetId: string;
  sourceHandle: string;
  targetHandle: string;
  lineType?: string; // orthogonal, straight, curved
}

interface RelationshipAddedParams extends IParams {
  sourceId: string;
  targetId: string;
}

export class RelationshipAddedEvent extends EventBase {
  static eventType = 'RelationshipAddedEvent';

  constructor(
    public readonly params: RelationshipAddedParams,
    public readonly source = AddRelationshipCommand,
  ) {
    super();
  }
}

export class AddRelationshipCommand extends CommandBase<AddRelationshipParams> {
  execute(params: AddRelationshipParams): RelationshipAddedEvent | CommandError {
    const { sourceId, targetId, sourceHandle, targetHandle } = params;
    const result = this.addRelationship(sourceId, targetId, sourceHandle, targetHandle, params.lineType);
    if (result instanceof Error) return CommandError.of(result, 'error');
    return new RelationshipAddedEvent({ sourceId, targetId });
  }

  private addRelationship(
    sourceId: string,
    targetId: string,
    sourceHandle: string,
    targetHandle: string,
    lineType?: string,
  ): void | Error {
    const modelContext = this.getModelContext();
    const source = modelContext.entityRepository.get(sourceId) as ConstructBase;
    if (!source) return new Error('Source entity not found');
    const target = modelContext.entityRepository.get(targetId) as ConstructBase;
    if (!target) return new Error('Target entity not found');
    if (source === target) return new Error('Cannot create a relationship with the same entity');
    if (source instanceof Preview || target instanceof Preview)
      return new Error('Cannot create a relationship with an upload preview');
    const sourceHandleLocation = strToHandleLocation(sourceHandle);
    if (sourceHandleLocation instanceof Error) return sourceHandleLocation;
    const targetHandleLocation = strToHandleLocation(targetHandle);
    if (targetHandleLocation instanceof Error) return targetHandleLocation;

    const edge = new Edge({
      id: sid(),
      source: source,
      target: target,
      sourceHandleLocation: sourceHandleLocation,
      targetHandleLocation: targetHandleLocation,
    });
    if (source.type === 'Command' || source.type === 'Event' || source.type === 'State') {
      edge.updateColor(source.style.backgroundColor);
    }
    if (lineType) {
      edge.updateLineType(lineType);
    }
    modelContext.entityRepository.add(edge);
    //source.edges.push(edge);
    //target.edges.push(edge);
    modelContext.entityRepository.update(source);
    modelContext.entityRepository.update(target);
  }
}
